<template>
  <section class="header">
    <div class="bg-part pb-8" :style="{ '--part-color': payload.hintergrundfarbe }">
      <div class="constrain relative h-full">
        <div ref="image" v-view class="animate in-left delay-3 image">
          <Image :src="payload.bild" :lazy="false" />
        </div>
        <div class="absolute top-24 lg:top-8 z-10">
          <h1 class="md:w-3/4 text-white" v-html="parsedText" />
        </div>
      </div>
    </div>
    <div class="absolute bottom-8 left-6 w-full text-center text-black">
      <Arrow class="inline h-8 cursor-pointer" @click="scroll" />
    </div>
  </section>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import Arrow from '@/assets/images/arrow.svg';
import Image from '@/components/utils/Image.vue';

export default {
  components: { Image, Arrow },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const image = ref(null);

    onMounted(() => {
      setTimeout(() => {
        image.value.classList.remove('delay-3');
      }, 1000);
    });

    return {
      image,
      parsedText: computed(() => {
        if (props.payload.titel.endsWith('Basel')) {
          return `${props.payload.titel.replace('Basel', '<span class="block text-black">Basel</span>')}`;
        }

        return props.payload.titel;
      }),
    };
  },
  methods: {
    scroll() {
      window.scrollBy({
        top: window.innerHeight * 0.8,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.header {
  height: calc(100vh - var(--header-height));
}

.bg-part {
  background: var(--part-color);
  height: calc(100vh - var(--header-height) - 6rem);
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  height: 100%;

  @screen md {
    position: relative;
  }

  img {
    @apply w-full h-full;
    object-fit: cover;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(to right, var(--part-color) 0%, transparent 60%);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
}
</style>
